/*global  jQuery*/

jQuery(function ($) {
  "use strict";
  
  var windowWidth = window.innerWidth;
  $(window).on("load", function () {
    /* 
    --------------------------------------
     ページ遷移を伴う頭出しの位置調整　ヘッダー固定時に使用
    --------------------------------------
    */
//    var wkhash=window.location.hash
//    if( wkhash != ""){
//      var windowWidth = window.innerWidth;
//      if( windowWidth > 768 ){
//        $(wkhash).velocity("scroll", {duration:500,offset:-182});  //ヘッダーサイズをオフセット
//      }else {
//        $(wkhash).velocity("scroll", {duration:500,offset:-52});  //ヘッダーサイズをオフセット　スマホ時
//      }
//    }
    /* 
    --------------------------------------
     HeightLine 要heightline.js
     高さを揃えるクラスを指定する 
    --------------------------------------
    */
    $(".place-info").heightLine({minWidth: 750});
    $(".card-needs .card-text").heightLine({minWidth: 750});
    $(".card-height01").heightLine({minWidth: 992});
    $(".work-height01").heightLine({minWidth: 992});
    $(".work-height02").heightLine({minWidth: 992});
    $(".card-home-product").heightLine();
//    $(".card-product").heightLine();
    $(".business-menu .menu-item a").heightLine();    
  });
  
  var timer = false;
  $(window).resize(function() {
      if (timer !== false) {
          clearTimeout(timer);
      }
      timer = setTimeout(function() {
//          console.log('resized');
          // 何らかの処理
          windowWidth = window.innerWidth;
      }, 200);
  });
  
  
  /* 
  --------------------------------------
   モーダル
   colorbox
  --------------------------------------
  */
  $("a[rel='colorbox-group']").colorbox({
    iframe: false,
    inline: false,
    maxWidth: "80%",
    maxHeight: "80%",
    opacity: 0.7
  });
  $("a[href$='.jpg'],a[href$='.png'],a[href$='.jpeg']").colorbox({
    iframe: false,
    inline: false,
    maxWidth: "80%",
    maxHeight: "80%",
    opacity: 0.7
  });
  $("a.popup").colorbox({
    iframe: false,
    inline: true,
    maxWidth: "90%",
    maxHeight: "90%",
    opacity: 0.7
  });
  $(".privacy-link").colorbox({
    iframe: false,
    inline: true,
    maxWidth: "950px",
    maxHeight: "90%",
    opacity: 0.7
  });
  /*
  --------------------------------------
   スムーズスクロール
   #で始まるアンカーをクリックした場合に処理
   必要に応じて除外する要素を追記すること
  --------------------------------------
  */
  $(document).on('click', 'a[href*=\\#]:not([href=\\#],.popup,no-pop,.popup2,.privacy-link,.card-lineup_work)', function (event) {
      // アンカーの値取得
      var href = $(this).attr("href"),
      // 移動先を取得 velocity
      target = $(href === "#" || href === "" ? 'html' : href);
    // スムーススクロール
    target.velocity("scroll", 500);
    // 移動先を数値で取得 animate
//    target = $(href === "#" || href === "" ? 'html' : href),
//    position = target.offset().top;
    // スムーススクロール
//    $('body,html').animate({scrollTop: position}, speed, 'swing');
    return false;
  });
  //ページトップ戻るボタン 動作
  $(document).on("click", ".btn-pagetop", function () {
    console.log("pagetop");
    $('body,html').velocity("scroll", 500);
    return false;
  });
  //ページトップ戻るボタン 表示ギミック
  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
//      $("#btn-pagetop").fadeIn(300);
      $(".btn-pagetop").addClass('visible');
    } else {
//      $("#btn-pagetop").fadeOut(300);
      $(".btn-pagetop").removeClass('visible');
    }
  });
  
  /* 
  --------------------------------------

  スマホ用メニュー処理

  --------------------------------------
  */    
  /* iOS動作時の調整処理 */
  $(document).on("touchstart", ".menu-btn", function (event) {
    event.stopPropagation();
  });
  /* ハンバーガーメニューによる開閉処理 */
  $(document).on("click", ".menu-btn.close", function () {
      $(".sp-menu").addClass("open");
      $(".main-contents").addClass("open");
      $(".sp-menu").removeClass("close");
      $(".menu-btn").addClass("open");
      $(".menu-btn").removeClass("close");
  });
  $(document).on("click", ".menu-btn.open", function () {
      $(".sp-menu").addClass("close");
      $(".sp-menu").removeClass("open");
      $(".main-contents").removeClass("open");
      $(".menu-btn").removeClass("open");
      $(".menu-btn").addClass("close");
  });

  $(document).on("click", ".company-h2", function () {
//    console.log(windowWidth);
    if( windowWidth < 991 ){
//      $(this).next(".company-contents").slideToggle();
      $(this).next(".company-contents").toggleClass('open');
      $(this).toggleClass('open');
    }
  });
  
    //コスチュームカルーセル
  $('.top-slide_box').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    dots: false,
    speed: 1000,
    fade: true,
    pauseOnFocus: false,
    pauseOnHover: false,
  });
  
});